<!--
 * @Author: xiaziwen
 * @Date: 2021-01-06 16:37:00
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-13 16:18:03
 * @FilePath: \crm-education\src\views\base\home.vue
-->
<template>
  <router-view></router-view>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'base_home',
  data() {
    return {}
  },
  computed: {
    ...mapState(['userRole'])
  },
  mounted() {
    if (this.userRole === 'STUDENT') {
      this.$router.push('/student')
    } else if (this.userRole === 'TEACHER') {
      this.$router.push('/teacher')
    }
  }
}
</script>
